import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class FormulaService {
    readFormulaQuery(){
        var query = `
            query ($paging:ServerPaging) {
                GetFormula (Paging:$paging) {
                    Formula {
                        formula_id
                        formula_name
                        type_detail_id
                        type_detail_name
                        quantity
                        base_uom
                        formula_machine {
                            machine_id
                            machine_name
                            formula_id
                            duration_min
                            created_at
                            last_update
                        }
                    }
                    Total
                }
            }
        `;
        return query;
    }

    async getFormulaByIdQuery(id){
        const variables = {
            id : id
        }
        var query = gql`
            query ($id:String) {
                GetFormula (FormulaId:$id) {
                    Formula{
                        formula_id
                        formula_name
                        type_detail_id
                        type_detail_name
                        quantity
                        base_uom
                        waste_percentage
                        formula_machine {
                            machine_id
                            machine_name
                            formula_id
                            duration_min
                            created_at
                            last_update
                        }
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetFormula;
    }

    getTypeDetailDataQuery(){
        var query = `
            query {
                GetTypeDetail(Flags:["All","Project","Retail"]){
                    type_detail_id
                    type_detail_name
                }
            }
            
        `;
        return query;
    }
    
    async getMachineDropdown(){
        var query = gql`
            query {
                GetMachine (active_flag:"A") {
                    machine_id
                    machine_name
                }
            }
            
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        return globalfunc.objectToArrayConverter(result.data.GetMachine, 'Formula-MachineDropdown');
    }

    async addQuery(variables){
        var query = gql`
            mutation($data: NewFormula!) {
                CreateFormula(NewFormula: $data)
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`
            mutation( $data: NewFormula!) {
                UpdateFormula( UpdatedFormulaRecipe: $data )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    async deleteQuery(variables){
        var query = gql`
            mutation( $id: String!) {
                DeleteFormula( FormulaId: $id )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async getItem(){
        var query = gql`
            query{
                GetProductMaster (isFormula: true) {
                    ProductMaster {
                      item_id
                      product_code
                      item_name
                      barcode
                      active_flag
                      item_type
                      type_detail_id
                      base_uom
                      base_weight
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var itemData = [];
        for (let i = 0; i < result.data.GetProductMaster.ProductMaster.length; i++) {
            var label = result.data.GetProductMaster.ProductMaster[i].product_code + ' (' + result.data.GetProductMaster.ProductMaster[i].item_name + ')'
            var str = {value:result.data.GetProductMaster.ProductMaster[i].item_id, label:label}
            itemData.push(str);
        }
        return itemData
    }
    async getItemById(id){
        const variables = {
            id:id
        }
        var query = gql`
            query($id:String){
                GetProductMaster (isFormula: true, itemID:$id) {
                    ProductMaster {
                      item_id
                      product_code
                      item_name
                      barcode
                      active_flag
                      item_type
                      type_detail_id
                      base_uom
                      base_weight
                    
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetProductMaster.ProductMaster[0];
    }
    gridDataConverter(data){
        let array = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                item_id: data[i].item_id,
                item_name: data[i].item_name,
                stock: data[i].stock,
                qty_change: data[i].qty_change,
                type_detail_id: data[i].type_detail_id
            }
            array.push(str);
        }
        return array;
    }

    gridSelector(selected, checkall, data){
        if (checkall == 'true'){
            if(selected == ''){
                var newData = data;
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.type_detail_id]= el
                })
                for (let i = 0; i < selectedData.length; i++) {
                    delete hashMap[selectedData[i].toString()]
                }
                var newData = [];
                Object.keys(hashMap).forEach(el=>{
                    newData.push(hashMap[el])
                })
                return newData;
            }
        }else{
            if(selected == ''){
                var newData = [];
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.type_detail_id]= el
                })
                var newData = [];
                for (let i = 0; i < selectedData.length; i++) {
                    newData.push(hashMap[selectedData[i].toString()])
                }
                return newData;
            }
        }
    }

}

export default new FormulaService();
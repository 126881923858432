<template>
    <div>
        <input hidden type="text" class="form-control" id="FormulaId" v-model="FormulaId">
        <div class="modal fade" id="FormulaModal" tabindex="-1" aria-labelledby="FormulaModalLabel" aria-hidden="true" style="overflow:auto;">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="FormulaModalLabel" class="font-weight-bold">{{ModalTitle}} {{this.ItemIdFormula == null ? "": this.ItemIdFormula.label}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold required">Kode Formula Item</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <v-select id="ItemDdl" class="pb-3" :options="ItemIdFormulaData" v-model="ItemIdFormula" :disabled="disabledValue" @input="onChangeItem()" />
                                    <label id="errorItemDdl" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Satuan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="BaseUom" v-model="BaseUom" class="font-weight-bold" readonly/>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Beban</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="BaseWeight" v-model="BaseWeight" class="font-weight-bold" readonly/>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Persentasi Waste</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="WastePercentage" v-model="WastePercentage" class="font-weight-bold" type="number"/>
                                    <label id="errorWastePercentage" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <hr>

                            <CRow>
                                <div class="p-3 border rounded" style="width: 100%;">
                                    <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Tipe Detail</h4></label>
                                    <hr>

                                    <div class="py-2">
                                        <button class="btn btn-primary"                     
                                            @click="addFormClick()">
                                            <i class="fa fa-plus"></i> Add
                                        </button>
                                    </div>

                                    <datasource ref="typeDetailDataSource" :data="this.TypeDetailGridData" :page-size="10" :schema-model-fields="this.TypeDetailGridDataSchemaModel"/>

                                    <kendo-grid ref="gridItem"
                                        :data-source-ref="'typeDetailDataSource'"
                                        :editable="TypeDetailGridEditTable"
                                        :pageable="true"
                                        :resizable="true"
                                        >
                                        
                                        <kendo-grid-column  :field="'type_detail_id'"
                                                            :title="'Kode Tipe Detail'"
                                                            :width="200"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'type_detail_name'"
                                                            :title="'Nama Tipe Detail'"
                                                            :width="200"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'qty'"
                                                            :title="'Qty'"
                                                            :width="200"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :title="'&nbsp;'"
                                                            :attributes="{ class: 'k-text-center' }"
                                                            :command="['destroy']"
                                                            :width="200"
                                                            ></kendo-grid-column>

                                    </kendo-grid>
                                </div>
                            </CRow>
                            
                            <br>
                            <CRow>
                                <div class="p-3 border rounded" style="width: 100%;">
                                    <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Machine</h4></label>
                                    <hr>
                                    <datasource ref="MachineDataSource" :data="this.MachineGridData" :page-size="10" :schema-model-fields="this.MachineGridDataSchemaModel"/>

                                    <kendo-grid ref="gridMachine"
                                            :data-source-ref="'MachineDataSource'"
                                            :pageable="true"
                                            :editable="true"
                                            :scrollable="true"
                                            :toolbar="['create']"
                                            :cellClose="cellClose"
                                            :resizable="true">

                                        <kendo-grid-column  :field="'machine_id'"
                                                            :title="'Kode Mesin'"
                                                            :width="200"
                                                            :editor= "machineDropDownEditor"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'machine_name'"
                                                            :title="'Nama Mesin'"
                                                            :width="200"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                        
                                        <kendo-grid-column  :field="'duration_min'"
                                                            :title="'Durasi (Menit)'"
                                                            :width="200"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                    </kendo-grid>
                                </div>
                            </CRow>

                        </div>
                        <br>

                        <label id="errorItemGeneral" class="form-error" style="display: none; color: red;"></label>                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import FormulaService from '../Script/FormulaServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'FormulaForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['reload', 'addItemClick'],
    data: function () {
        return {
            ModalTitle: '',

            FormulaId:'',
            BaseUom:'',
            BaseWeight:'',
            WastePercentage: 0,

            ItemIdFormula: '',
            ItemIdFormulaData: [],

            disabledValue: '',

            TypeDetailDataList:[],
            TypeDetailGridData: [],
            TypeDetailGridDataSchemaModel: {
                type_detail_id : {type: "string", editable: false},
                type_detail_name : {type: "string", editable: false},
                qty: { type: "number", editable: true, validation: { min: 0, required: true} },
            },
            TypeDetailGridEditTable: true,

            MachineData: [],
            MachineDetailDataList:[],
            MachineGridData: [],
            MachineGridDataSchemaModel: {
                machine_id : {type: "string", editable: true},
                machine_name : {type: "string", editable: false},
                duration_min: { type: "number", editable: true, validation: { min: 0, required: true} },
            },
            MachineGridEditTable: true,
            
            SaveType: '',
            Error: 0,
            gridDetailReload: 0,
        }
    },
    methods: {
        async machineDropDownEditor(container, options) {
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.MachineData
                    }
                });
        },
        cellClose(e){
            var grid = this.$refs.gridMachine.kendoWidget();
            var dataItem = e.sender.dataItem($(e.container).parent());
            
            var machineData = this.MachineData.find(c => c.value == dataItem.machine_id);
            dataItem.machine_name = machineData.label;
            dataItem.machine_id = machineData.value;
            this.MachineGridData = this.$globalfunc.objectToArrayConverter(grid.dataSource._data, 'Formula-MachineGrid');

            // var duplicate = this.MachineGridData.find(c => c.machine_id == dataItem.machine_id);

            // if (duplicate == undefined) {
            //     var machineData = this.MachineData.find(c => c.value == dataItem.machine_id);
            //     dataItem.machine_name = machineData.label;
            //     dataItem.machine_id = machineData.value;

            //     this.MachineGridData = this.$globalfunc.objectToArrayConverter(grid.dataSource._data, 'Formula-MachineGrid');
            // }
            // else {
            //     dataItem.machine_name = null;
            //     dataItem.machine_id = null;
            //     this.$swal("Error", "Mesin tidak boleh sama", "error");
                
            //     var data = this.$globalfunc.objectToArrayConverter(grid.dataSource._data, 'Formula-MachineGrid');
            //     this.MachineGridData = data.filter(function( obj ) {
            //             return obj.machine_id !== null;
            //         });
            // }
        },
        async addClick(){    
            $(".form-error").css("display", "none");
            this.ItemGridEditTable = true;
            this.ModalTitle = 'Add Formula: ';

            this.FormulaId = '';
            this.disabledValue = false;

            this.ItemIdFormulaData = await FormulaService.getItem();
            this.ItemIdFormula = '';
            this.BaseUom = '';
            this.BaseWeight = '';
            this.WastePercentage = 0;

            this.TypeDetailGridData = [];

            this.MachineData = await FormulaService.getMachineDropdown()
            this.MachineGridData = [];
            
            this.SaveType = 'Add';
            
            window.$('#FormulaModal').modal('show');
        },
        async editClick(id, View){
            $(".form-error").css("display", "none");
            this.ItemGridEditTable = false;
            this.ModalTitle = 'Edit Formula: ';

            var data = await FormulaService.getFormulaByIdQuery(id);

            this.FormulaId = data.Formula[0].formula_id;
            this.disabledValue = true;

            this.ItemIdFormulaData = await FormulaService.getItem()
            this.ItemIdFormula = this.ItemIdFormulaData.find(c => c.value == data.Formula[0].formula_id);
            var itemData = await FormulaService.getItemById(this.ItemIdFormula.value);

            this.BaseUom = itemData.base_uom;
            this.BaseWeight = itemData.base_weight;
            this.WastePercentage = data.Formula[0].waste_percentage;

            this.TypeDetailGridData = globalfunc.objectToArrayConverter(data.Formula, "Formula-RecipeDetailData");
            
            this.MachineData = await FormulaService.getMachineDropdown()
            this.MachineGridData = this.$globalfunc.objectToArrayConverter(data.Formula[0].formula_machine, 'Formula-MachineGrid');
            
            this.SaveType = 'Edit';
            this.reloadDetail();
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Formula: ';
            }

            window.$('#FormulaModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.WastePercentage.toString() == '' || this.WastePercentage.toString() == null){
                this.$swal("Error", "Persentasi Waste tidak boleh kosong", "error");
                this.Error++;
            }
            
            ////Type Detail Validation
            this.TypeDetailDataList = this.$refs.gridItem.kendoWidget().dataSource._data;
            var dataFormula = await FormulaService.getFormulaByIdQuery(this.ItemIdFormula.value);

            if ( this.ItemIdFormula.value === undefined){
                this.$swal("Error", "Kode Item tidak boleh kosong", "error");
                this.Error++;
            }
            else if(dataFormula.Formula != null && this.SaveType == "Add"){
                this.$swal("Error", "Formula sudah ada", "error");
                this.Error++;
            }
            else if(this.TypeDetailDataList.length == 0){
                this.$swal("Error", "Type Detail Tidak boleh kosong", "error");
                this.Error++;
            }
            
            for (let i = 0; i < this.TypeDetailDataList.length; i++) {
                if ( this.TypeDetailDataList[i].qty === undefined || this.TypeDetailDataList[i].qty <= 0 ){
                    this.$swal("Error", "Jumlah Qty tidak boleh kosong atau lebih kecil dari 1", "error");
                    this.Error++;
                }
            }

            ////Machine Validation
            this.MachineDetailDataList = this.$refs.gridMachine.kendoWidget().dataSource._data;

            if(this.MachineDetailDataList.length == 0){
                this.$swal("Error", "Mesin Tidak boleh kosong", "error");
                this.Error++;
            }             
            
            for (let i = 0; i < this.MachineDetailDataList.length; i++) {
                if ( this.MachineDetailDataList[i].duration_min === undefined || this.MachineDetailDataList[i].duration_min <= 0 ){
                    this.$swal("Error", "Durasi pada Mesin tidak boleh kosong atau lebih kecil dari 1", "error");
                    this.Error++;
                }
            }
            
        },
        async saveClick() {
            await this.inputValidation();
            if(this.Error == 0){
                var TypeDetailArray = [];
                var MachineDetailArray = [];

                for (let i = 0; i < this.TypeDetailDataList.length; i++) {
                    var detail = { 
                        type_detail_id: this.TypeDetailDataList[i].type_detail_id, 
                        quantity: this.TypeDetailDataList[i].qty
                    }
                    TypeDetailArray.push(detail)
                }
                
                for (let i = 0; i < this.MachineDetailDataList.length; i++) {
                    var detail = { 
                        machine_id: this.MachineDetailDataList[i].machine_id, 
                        duration_min: this.MachineDetailDataList[i].duration_min
                    }
                    MachineDetailArray.push(detail)
                }   

                const newformula = {
                    formula_id : this.ItemIdFormula.value,
                    waste_percentage : parseFloat(this.WastePercentage),
                    FormulaDetail : TypeDetailArray,
                    FormulaMachine : MachineDetailArray
                }

                if (this.SaveType == 'Add') {
                    this.$loading(true);

                    const variables = {
                        data : newformula
                    }
                    
                    FormulaService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#FormulaModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if(this.SaveType == 'Edit') {
                    this.$loading(true);

                    const variables = {
                        data: newformula
                    }
                        
                    FormulaService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#FormulaModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        reloadDetail(){
            this.gridDetailReload++;
        },
        addFormClick(){
            this.$props.addItemClick(this.StorageId);
        },
        ItemDetailGridData(data){
            var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;
            var FormulaTypeDetailData = globalfunc.objectToArrayConverter(gridData, "Formula-TypeDetailData");

            var inputData = globalfunc.objectToArrayConverter(data, "Formula-TypeDetailData");
            FormulaTypeDetailData = FormulaTypeDetailData.concat(inputData);

            this.TypeDetailGridData  = Object.values(FormulaTypeDetailData.reduce((a, c) => (a[`${c.type_detail_id}${c.type_detail_name}`] = c, a), {})); 
        },
        async onChangeItem(){
            var id = this.ItemIdFormula.value;
            var itemData = await FormulaService.getItemById(id);
            this.BaseUom = itemData.base_uom;
            this.BaseWeight = itemData.base_weight;
        },
    }
}
</script>

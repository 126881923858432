<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="Formula"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import FormulaServices from '../Script/FormulaServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'FormulaGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['deleteClick','editClick'],
    computed: {
        Formula () {
            return new kendo.data.DataSource({
                page: 1,
                pageSize: 50,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            return { 
                                query: FormulaServices.readFormulaQuery(),
                                variables : {
                                    paging : paging
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetFormula == null){
                            return [];
                        }else{
                            return response.data.GetFormula.Formula;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetFormula == null){
                            return 0;
                        }else{
                            return response.data.GetFormula.Total;
                        }
                    }
                },
                group: [
                    { field: "formula_name" },
                ],
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "formula_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "type_detail_id", title: "Kode Type Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "type_detail_name", title: "Nama Type Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "quantity", title: "Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },

            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
            
        };
    },
    mounted: async function() {
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            editClick(dataItem.formula_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.formula_id, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.formula_id);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Formula")
        },
    },
    
}
</script>